import gql from "graphql-tag";
import { client } from "@/api/apollo";
import { CrudService, PageConsult, PageConsultInput } from "./Services";
import { clone, filterKeys } from "@/utils/object";

let instance: CategoriaService;

export interface Categoria {
  id: number;
  nome: string;
  fatorPontuacao: number;
  createdAt?: Date;
  updatedAt?: Date;
  status: string;
  dataLiberacao?: string;
  descontoAfiliado?: number;
  listarPrimeiroPedido?: number;
  descontoNo1pedido: boolean;
  exclusivoPa?: boolean;
  descontoAdicionalPa?: number;
  exclusivo1pedido?: boolean;
}

export const initialCategoria: Readonly<Categoria> = Object.freeze({
  id: null!,
  nome: null!,
  fatorPontuacao: null!,
  createdAt: null!,
  updatedAt: null!,
  status: null!,
  dataLiberacao: null!,
  descontoNo1pedido: true,
  exclusivoPa: false,
  descontoAdicionalPa: null!,
  exclusivo1pedido: false,
});

export function getInstance(): CategoriaService {
  if (!instance) {
    instance = new CategoriaService();
  }
  return instance;
}

export interface CategoriaPageConsultInput extends PageConsultInput {
  somenteAtivas?: boolean;
}

export default class CategoriaService implements CrudService<Categoria> {
  async consultar(
    variables: CategoriaPageConsultInput,
  ): Promise<PageConsult<Categoria>> {
    const result = await client
      .query({
        variables,
        query: gql`
          query (
            $q: String
            $first: Int!
            $page: Int!
            $sortField: String!
            $sortOrder: SortOrder!
            $somenteAtivas: Boolean
          ) {
            categorias(
              q: $q
              first: $first
              page: $page
              orderBy: {
                column: $sortField
                order: $sortOrder
              }
              somenteAtivas: $somenteAtivas
            ) {
              data {
                id
                nome
                fatorPontuacao
                status
                updatedAt
                createdAt
                dataLiberacao
                descontoAfiliado
                listarPrimeiroPedido
                descontoNo1pedido
                exclusivo1pedido
              }
              paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
              }
            }
          }
        `,
      });
    const { data: { categorias } } = result;
    return categorias;
  }

  private prepareData(input: Categoria) {
    const data = filterKeys(clone(input), [
      "nome",
      "fatorPontuacao",
      "status",
      "dataLiberacao",
      "descontoAfiliado",
      "listarPrimeiroPedido",
      "descontoNo1pedido",
      "exclusivoPa",
      "descontoAdicionalPa",
      "exclusivo1pedido",
    ]);
    return data;
  }

  async cadastrar(input: Categoria): Promise<number> {
    const result = await client
      .mutate({
        variables: {
          input: this.prepareData(input),
        },
        mutation: gql`
          mutation($input: CategoriaInput!) {
            createCategoria(input: $input)
          }
        `,
      });
    const { data: { createCategoria } } = result;
    return createCategoria;
  }

  async atualizar(id: number, data: Categoria) {
    await client
      .mutate({
        variables: {
          id,
          input: this.prepareData(data),
        },
        mutation: gql`
          mutation($id: ID!, $input: CategoriaInput!) {
            updateCategoria(id: $id input: $input)
          }
        `,
      });
  }

  async excluir(id: number) {
    await client
      .mutate({
        variables: {
          id,
        },
        mutation: gql`
          mutation($id: ID!) {
            deleteCategoria(id: $id)
          }
        `,
      });
  }

  async getById(id: number): Promise<Categoria> {
    const result = await client
      .query({
        variables: { id },
        query: gql`
          query ($id: ID!) {
            categoria(id: $id) {
              id
              createdAt
              updatedAt
              nome
              fatorPontuacao
              status
              dataLiberacao
              listarPrimeiroPedido
              descontoAfiliado
              descontoNo1pedido
              exclusivoPa
              descontoAdicionalPa
              exclusivo1pedido
            }
          }
        `,
      });
    const { data: { categoria } } = result;
    return categoria;
  }
}
