
import { http } from "@/api/rest";
import { Item } from "@/services/ItemService";
import { clone } from "@/utils/object";
import { useToast } from "primevue/usetoast";
import {
  defineComponent,
  ref,
  watch,
} from "vue";
import ForeignItem from "./ForeignItem.vue";

interface EstoqueMov {
  item: Item;
  qtd: number;
}

const initialEstoqueMov: EstoqueMov = {
  item: null!,
  qtd: null!
}

export default defineComponent({
  emits: ["update:visible", "movimentou"],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: { ForeignItem },
  setup(props, { emit }) {
    const toast = useToast();
    const sending = ref(false);

    const mov = ref<EstoqueMov>(clone(initialEstoqueMov));

    function reset() {
      mov.value = clone(initialEstoqueMov);
    }

    async function movimentar() {
      if (!mov.value.item) {
        return;
      }
      sending.value = true;
      try {
        const data = {
          itemId: mov.value.item.id,
          qtd: mov.value.qtd
        };
        await http.post("/api/estoque/movimentar", data);
        toast.add({
          severity: "success",
          summary: "Estoque atualizado",
          life: 2_000,
        });
        emit("movimentou");
        reset();
      } finally {
        sending.value = false;
      }
    }

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    function close() {
      if (!sending.value) {
        emit("update:visible", false);
      }
    }

    return {
      sending,
      mov,

      close,
      movimentar,

      updateDialogVisible(v: boolean) {
        if (!v && sending.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
