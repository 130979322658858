<template>
  <p-dialog
    header="Mov. Estoque"
    :visible="visible"
    @update:visible="updateDialogVisible"
    modal
    style="max-width: 1024px"
  >
    <form class="p-grid" @submit.prevent="movimentar">
      <div class="p-fluid p-col-12">
        <div class="p-field">
          <label>Item</label>
          <ForeignItem v-model="mov.item" />
        </div>
        <div class="p-field">
          <label>Quantidade</label>
          <p-inputnumber
            v-model="mov.qtd"
            required
            :minFractionDigits="0"
            :maxFractionDigits="0"
          />
        </div>
      </div>

      <div
        class="p-col-12 p-d-flex p-flex-row-reverse"
        style="justify-content: end"
      >
        <p-button
          label="Salvar"
          :icon="`pi pi-save${sending ? ' p-spin' : ''}`"
          :disabled="sending"
          type="submit"
        />
        <p-button
          label="Cancelar"
          class="p-button-secondary p-mr-2"
          @click="close"
        />
      </div>
    </form>
  </p-dialog>
</template>

<script lang="ts">
import { http } from "@/api/rest";
import { Item } from "@/services/ItemService";
import { clone } from "@/utils/object";
import { useToast } from "primevue/usetoast";
import {
  defineComponent,
  ref,
  watch,
} from "vue";
import ForeignItem from "./ForeignItem.vue";

interface EstoqueMov {
  item: Item;
  qtd: number;
}

const initialEstoqueMov: EstoqueMov = {
  item: null!,
  qtd: null!
}

export default defineComponent({
  emits: ["update:visible", "movimentou"],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: { ForeignItem },
  setup(props, { emit }) {
    const toast = useToast();
    const sending = ref(false);

    const mov = ref<EstoqueMov>(clone(initialEstoqueMov));

    function reset() {
      mov.value = clone(initialEstoqueMov);
    }

    async function movimentar() {
      if (!mov.value.item) {
        return;
      }
      sending.value = true;
      try {
        const data = {
          itemId: mov.value.item.id,
          qtd: mov.value.qtd
        };
        await http.post("/api/estoque/movimentar", data);
        toast.add({
          severity: "success",
          summary: "Estoque atualizado",
          life: 2_000,
        });
        emit("movimentou");
        reset();
      } finally {
        sending.value = false;
      }
    }

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    function close() {
      if (!sending.value) {
        emit("update:visible", false);
      }
    }

    return {
      sending,
      mov,

      close,
      movimentar,

      updateDialogVisible(v: boolean) {
        if (!v && sending.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
</script>